:root {
  --accent-color-from: hsl(322, 100%, 50%);
  --accent-color-to: hsl(259, 100%, 50%);
  --accent-color: linear-gradient(
    90deg,
    var(--accent-color-from),
    var(--accent-color-to)
  );
  --primary-background-color: hsl(240, 6%, 6%);
  --secondary-background-color: hsl(240, 8%, 12%);
  --tertiary-background-color: hsl(255, 6%, 26%);
  --primary-font-color: hsl(0, 0%, 100%);
}

@font-face {
  font-family: "Poppins";
  src: url("/src/assets/Poppins-Regular.ttf");
}

body {
  margin: 0;
  background-color: var(--primary-background-color);
  color-scheme: dark;
  font-family: "Poppins", sans-serif !important;
  color: var(--primary-font-color);
}

p {
  margin: 0;
}
